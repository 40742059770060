import { useRouter } from 'next/router'
import Content from 'components/LandingContent'
import HowItWorks from 'components/HowItWorks'
import GlobalBrands from 'components/GlobalBrands'
import NewToFlavorWiki from 'components/NewToFlavorWiki'
import YourOpinionMatters from 'components/YourOpinionMatters'
import Reviews from 'components/Reviews'
import Navbar from 'components/NavBar'
import Footer from 'components/Footer'
import { PageContainer } from 'components/pagesStyle/home/styles'
import useLanguageRoute from 'hooks/useLanguageRoute'
import Meta from 'components/Meta'
import { getMetadata } from 'api/metadataApi'

const Home = ({ meta }) => {
  const router = useRouter()
  const query = router.query
  useLanguageRoute()

  return (
    <>
      <Meta
        path='en'
        image={meta['en'].image}
        title={meta['en'].title}
        description={meta['en'].description}
      />
      <PageContainer>
        <Navbar currentPage={router.pathname} language='en' route='/'></Navbar>
        <main>
          <Content source={query.source ? query.source : 'fw-site'} lang='en'></Content>
          <HowItWorks source={query.source ? query.source : 'fw-site'} lang='en' />
          <NewToFlavorWiki />
          <GlobalBrands />
          <YourOpinionMatters />
          <Reviews />
        </main>
        <Footer></Footer>
      </PageContainer>
    </>
  )
}

export async function getStaticProps (context) {
  const response = await getMetadata()
  if (!response) {
    return null
  }

  return {
    props: {
      meta: response[0].LinkPreviewsData
    }
  }
}

export default Home
