import { GET } from 'utils/axios'

export const getGeoDetails = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_CLOUDFARE_URL}/cdn-cgi/trace`
    const response = await GET(url)
    if (response) {
      const { data } = response
      let dataJson = data.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"')
      dataJson = '{"' + dataJson.slice(0, dataJson.lastIndexOf('","')) + '"}'
      const json = JSON.parse(dataJson)
      return json
    }
  } catch (error) {
    return null
  }
}
