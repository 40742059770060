import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { LANGUAGES } from 'utils/constants'
import { getGeoDetails } from 'api/cloudflareApi'
import useQuery from 'hooks/useQuery'

const useLanguageRoute = (route = '/') => {
  const query = useQuery()
  const router = useRouter()

  useEffect(async () => {
    let source
    if (query) {
      if (query.source) {
        source = query.source
      }
    }
    const data = await getGeoDetails()
    if (data) {
      const loc = data.loc.toLowerCase()
      const language = LANGUAGES.find((lang) => lang.flags.includes(loc))
      if (language) {
        const link = source
          ? `${route}${language.value}?source=${source}`
          : `${route}${language.value}`
        router.push(link)
      } else {
        const link = source ? `${route}en?source=${source}` : `${route}en`
        router.push(link)
      }
    }
  }, [query])
}

export default useLanguageRoute
